import React, { useState, Component, useEffect } from "react";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWadoImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";
import Header from "./components/header";
import CornerstoneViewport from "react-cornerstone-viewport";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CustomOverlay extends Component {
  static propTypes = {
    scale: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
    windowCenter: PropTypes.number.isRequired,
    imageId: PropTypes.string.isRequired,
    imageIndex: PropTypes.number.isRequired,
    stackSize: PropTypes.number.isRequired,
  };

  render() {
    return <></>;
  }
}

export function App() {
  // Cornerstone Tools
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
  cornerstoneTools.init({
    showSVGCursors: true,
    globalToolSyncEnabled: true,
  });
  cornerstoneTools.toolColors.setActiveColor("white");
  cornerstoneTools.toolColors.setToolColor("yellow");

  const fontFamily = "Karla, sans-serif";

  cornerstoneTools.textStyle.setFont(`16px ${fontFamily}`);

  // Image Loader
  cornerstoneWadoImageLoader.external.cornerstone = cornerstone;
  cornerstoneWadoImageLoader.external.dicomParser = dicomParser;
  cornerstoneWadoImageLoader.webWorkerManager.initialize({
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: true,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        usePDFJS: false,
        strict: false,
      },
    },
  });

  const urlImage = window.location.search;
  const formatedUrl = urlImage.substring(urlImage.indexOf("://") + 3);
  const [series, setSeries] = useState(true);
  const [activeTool, setActiveTool] = useState("Pan");
  const [image, setImage] = useState(null);
  const [element, setElement] = useState(null);
  const [vflip, setVFlip] = useState(false);
  const [hflip, setHFlip] = useState(false);
  const [invert, setInvert] = useState(false);
  const [url, setUrl] = useState(formatedUrl);
  const [width, setWidth] = useState(0);
  const [sliderSettings, setSliderSettings] = useState({});

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (width < 500) {
      setSliderSettings({
        dots: true,
        slidesToShow: 2,
        swipeToSlide: true,
        slidesToScroll: 1,
        arrows: false,
      });
    }
    if (width >= 500 && width < 1300) {
      setSliderSettings({
        dots: true,
        slidesToShow: 3,
        swipeToSlide: true,
        slidesToScroll: 1,
        arrows: false,
      });
    }
  }, [width]);

  const [state, setState] = useState({
    activeViewportIndex: 0,
    viewports: [],
    tools: [
      {
        name: "StackScrollMouseWheel",
        mode: "active",
        modeOptions: {},
      },
      {
        name: "Pan",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "Zoom",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "Rotate",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "Wwwc",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "Angle",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "Length",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "ArrowAnnotate",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "Magnify",
        mode: "active",
        modeOptions: { mouseButtonMask: 4 },
      },
      {
        name: "Probe",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "RectangleRoi",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "EllipticalRoi",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "Bidirectional",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
      {
        name: "Eraser",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
      },
    ],
  });

  const imageIds =
    url === ""
      ? [
          [
            "dicomweb://raw.githubusercontent.com/cornerstonejs/cornerstoneWADOImageLoader/master/testImages/CT2_J2KR",
          ],
          [
            "dicomweb://s3.amazonaws.com/lury/PTCTStudy/1.3.6.1.4.1.25403.52237031786.3872.20100510032220.11.dcm",
            "dicomweb://s3.amazonaws.com/lury/PTCTStudy/1.3.6.1.4.1.25403.52237031786.3872.20100510032220.12.dcm",
          ],
          [
            "dicomweb://raw.githubusercontent.com/cornerstonejs/cornerstoneWADOImageLoader/master/testImages/CT1_J2KR",
          ],
          [
            "dicomweb://raw.githubusercontent.com/cornerstonejs/cornerstoneWADOImageLoader/master/testImages/CTImage.dcm",
          ],
        ]
      : [["dicomweb://" + url]];

  const handleClick = (value) => {
    if (value === "Series") {
      setSeries(!series);
    }

    if (value === "removeStack") {
      cornerstoneTools.setToolPassive("StackScrollMouseWheel", {});
    }

    if (value === "StackScrollMouseWheel") {
      setActiveTool(value);
    }

    if (value === "Pan") {
      setActiveTool(value);
    }

    if (value === "Zoom") {
      setActiveTool(value);
    }

    if (value === "Rotate") {
      setActiveTool(value);
    }

    if (value === "Wwwc") {
      setActiveTool(value);
    }

    if (value === "Angle") {
      setActiveTool(value);
    }

    if (value === "Length") {
      setActiveTool(value);
    }

    if (value === "ArrowAnnotate") {
      setActiveTool(value);
    }

    if (value === "Magnify") {
      setActiveTool(value);
    }

    if (value === "Probe") {
      setActiveTool(value);
    }

    if (value === "RectangleRoi") {
      setActiveTool(value);
    }

    if (value === "EllipticalRoi") {
      setActiveTool(value);
    }

    if (value === "Bidirectional") {
      setActiveTool(value);
    }

    if (value === "Eraser") {
      setActiveTool(value);
    }

    if (value === "Reset") {
      cornerstone.reset(element);
    }

    if (value === "Clear") {
      cornerstoneTools.clearToolState(element, "Length");
      cornerstoneTools.clearToolState(element, "Angle");
      cornerstoneTools.clearToolState(element, "ArrowAnnotate");
      cornerstoneTools.clearToolState(element, "EllipticalRoi");
      cornerstoneTools.clearToolState(element, "Probe");
      cornerstoneTools.clearToolState(element, "RectangleRoi");
      cornerstoneTools.clearToolState(element, "Bidirectional");
      let viewport = cornerstone.getViewport(element);
      cornerstone.setViewport(element, viewport);
    }

    if (value === "vflip") {
      setVFlip(!vflip);
    }

    if (value === "hflip") {
      setHFlip(!hflip);
    }

    if (value === "Invert") {
      setInvert(!invert);
    }
  };

  useEffect(() => {
    if (activeTool || vflip || hflip || invert) {
      setTimeout(() => {
        const canvas = document.querySelector(
          ".image .viewport-element canvas"
        );
        const image = canvas.toDataURL("image/png", 1.0);
        setImage(image);
      }, 1000);
    }
    console.log("trt");
  }, [activeTool, vflip, hflip, invert]);

  useEffect(() => {
    if (element) {
      let viewport = cornerstone.getViewport(element);
      viewport.vflip = vflip;
      viewport.hflip = hflip;
      viewport.invert = invert;
      cornerstone.setViewport(element, viewport);
    }
  }, [vflip, hflip, invert]);

  useEffect(() => {
    if (element) {
      const array = [];
      for (let i = 0; i < imageIds.length; i++) {
        array.push(i);
        setState({ ...state, viewports: array });
      }
    }
  }, [element, imageIds.length]);

  return (
    <>
      <Header
        onClick={handleClick}
        image={image}
        series={series}
        imageIdsLength={imageIds?.length}
        activeImageLength={imageIds[state?.activeViewportIndex]?.length}
        width={width}
      />
      {imageIds.length > 1 || width > 1144 ? (
        <div className="grid grid-cols-3 lg:grid-cols-6 grid-flow-col lg:gap-4 mt-4 sm:px-4 px-2">
          <div
            className={`${
              series
                ? "scroll-touch lg:grid snap-both snap-mandatory col-start-1 col-span-4 lg:col-start-1 lg:grid-flow-row lg:col-span-1 lg:grid-cols-1"
                : "hidden"
            } lg:overflow-y-auto lg:overflow-x-hidden  lg:h-[calc(98vh_-_88px)] gap-3 md:gap-5 mt-4 lg:mt-0 pr-2 ${
              imageIds.length < 2 ? "max-h-[210px]" : ""
            }`}
          >
            {width < 1300 ? (
              <Slider {...sliderSettings}>
                {state?.viewports?.map((viewportIndex) => {
                  return (
                    <div
                      key={viewportIndex}
                      className={`${
                        state.activeViewportIndex !== viewportIndex
                          ? "bg-gray-100"
                          : "bg-primary"
                      }  p-2 rounded-lg border cursor-pointer hideSlider`}
                    >
                      <CornerstoneViewport
                        imageIds={imageIds[viewportIndex]}
                        setViewportActive={() => {
                          setState({
                            ...state,
                            activeViewportIndex: viewportIndex,
                          });
                        }}
                        className={`md:max-w-[225px] sm:max-w-[190px] max-w-[180px] ${
                          width > 772 && "md:max-w-[240px]"
                        } ${width > 850 && "md:max-w-[310px]"} ${
                          width > 950 && width < 1151 && "md:max-w-[325px]"
                        } ${width > 1150 && "md:max-w-[390px]"}`}
                        viewportOverlayComponent={CustomOverlay}
                      />
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <>
                {state?.viewports?.map((viewportIndex) => {
                  return (
                    <div
                      key={viewportIndex}
                      className={`md:row-span-1 hideSlider ${
                        state.activeViewportIndex !== viewportIndex
                          ? "bg-gray-100"
                          : "bg-primary"
                      }  p-2 rounded-lg border cursor-pointer`}
                    >
                      <CornerstoneViewport
                        imageIds={imageIds[viewportIndex]}
                        setViewportActive={() => {
                          setState({
                            ...state,
                            activeViewportIndex: viewportIndex,
                          });
                        }}
                        className="min-h-[190px] max-h-[190px]"
                        viewportOverlayComponent={CustomOverlay}
                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div
            className={`col-span-6 col-start-1 row-start-1 ${
              series ? "lg:col-start-2" : "lg:col-start-1"
            } lg:row-span-1 h-[50vh] sm:h-[45vh] lg:h-[calc(98vh_-_88px)] bg-gray-100 p-2 rounded-lg border`}
          >
            <CornerstoneViewport
              tools={state.tools}
              className="image"
              imageIds={imageIds[state.activeViewportIndex]}
              activeTool={activeTool}
              onElementEnabled={(elementEnabledEvt) => {
                setElement(elementEnabledEvt.detail.element);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-3 lg:grid-cols-5 grid-flow-col lg:gap-4 mt-4 px-2">
          <div
            className={`col-span-3 lg:col-span-6 bg-gray lg:h-[calc(98vh_-_88px)] h-[60vh] bg-gray-100 p-3 rounded-lg border`}
          >
            <CornerstoneViewport
              tools={state.tools}
              imageIds={imageIds[state.activeViewportIndex]}
              activeTool={activeTool}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
