import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { GrRotateRight } from "react-icons/gr";
import { FaYinYang } from "react-icons/fa";
import { CgEditFlipV, CgEditFlipH } from "react-icons/cg";

import { HiDownload, HiOutlineAnnotation } from "react-icons/hi";
import {
  TfiAngleLeft,
  TfiRulerAlt,
  TfiShiftLeft,
  TfiShiftRight,
  TfiZoomIn,
} from "react-icons/tfi";
import { BiRectangle, BiDirections, BiReset } from "react-icons/bi";
import { BsCircle, BsTrash } from "react-icons/bs";
import { ImStack } from "react-icons/im";
import { CiEraser } from "react-icons/ci";
import { GiMagnifyingGlass } from "react-icons/gi";
import { IoMdExpand, IoMdMore } from "react-icons/io";
import { TbCircleDot } from "react-icons/tb";
import { MdInvertColors } from "react-icons/md";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({
  onClick,
  image,
  series,
  imageIdsLength,
  activeImageLength,
  width,
}) {
  const [active, setActive] = useState(1);
  const [stack, setStack] = useState(true);
  const handleClick = (value, index, stack) => {
    if (stack === false && value === "StackScrollMouseWheel") {
      setStack(true);
      onClick(value);
    }
    if (stack === true && value === "StackScrollMouseWheel") {
      setStack(false);
      onClick("removeStack");
    }
    if (value !== "Series" && index !== 0) {
      setActive(index);
      onClick(value);
    }
    if (value === "Series") {
      onClick(value);
    }
  };

  const moreTools = [
    {
      name: "Vergrößern",
      value: "Magnify",
      icon: <GiMagnifyingGlass size={24}
      className={`${active === 9 ? " fill-primary" : ""}`} />,
    },
    {
      name: "Sonde",
      value: "Probe",
      icon: <TbCircleDot size={24} 
      className={`${active === 10 ? " fill-primary" : ""}`} />,
    },
    {
      name: "Rechteck",
      value: "RectangleRoi",
      icon: <BiRectangle size={24}
      className={`${active === 11 ? " fill-primary" : ""}`} />,
    },
    {
      name: "Eklipse",
      value: "EllipticalRoi",
      icon: <BsCircle size={24}
      className={`${active === 12 ? " fill-primary" : ""}`} />,
    },
    {
      name: "Invertieren",
      value: "Invert",
      icon: <MdInvertColors size={24}
      className={`${active === 13 ? " fill-primary" : ""}`} />,
    },
    {
      name: "Vertikal drehen",
      value: "vflip",
      icon: <CgEditFlipV size={24}
      className={`${active === 14 ? " fill-primary" : ""}`} />,
    },
    {
      name: "Horizontal drehen",
      value: "hflip",
      icon: <CgEditFlipH size={24} 
      className={`${active === 15 ? " fill-primary" : ""}`} />,
    },
    {
      name: "Bidirektional",
      value: "Bidirectional",
      icon: <BiDirections size={24}
      className={`${active === 16 ? " fill-primary" : ""}`} />,
    },
    {
      name: "Radierer",
      value: "Eraser",
      icon: <CiEraser size={24}
      className={`${active === 17 ? " fill-primary" : ""}`} />,
    },
    {
      name: "Löschen",
      value: "Clear",
      icon: <BsTrash size={24}
      className={`${active === 18 ? " fill-primary" : ""}`} />,
    },
  ];

  const mobileTools = [
    {
      name: "Stapelrolle",
      value: "StackScrollMouseWheel",
      icon: <ImStack size={20} className={`${stack ? " fill-primary" : ""}`} />,
    },
    {
      name: "Schwenken",
      value: "Pan",
      icon: (
        <IoMdExpand
          size={20}
          className={`${active === 1 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Zoom",
      value: "Zoom",
      icon: (
        <TfiZoomIn
          size={20}
          className={`${active === 2 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Rotieren",
      value: "Rotate",
      icon: (
        <GrRotateRight
          size={20}
          className={`${active === 3 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Schattierungen",
      value: "Wwwc",
      icon: (
        <FaYinYang
          size={20}
          className={`${active === 4 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Winkel",
      value: "Angle",
      icon: (
        <TfiAngleLeft
          size={20}
          className={`${active === 5 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Länge",
      value: "Length",
      icon: (
        <TfiRulerAlt
          size={20}
          className={`${active === 6 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Anmerkung",
      value: "ArrowAnnotate",
      icon: (
        <HiOutlineAnnotation
          size={20}
          className={`${active === 7 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Zurücksetzen",
      value: "Reset",
      icon: (
        <BiReset
          size={20}
          className={`${active === 8 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Vergrößern",
      value: "Magnify",
      icon: (
        <GiMagnifyingGlass
          size={20}
          className={`${active === 9 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Sonde",
      value: "Probe",
      icon: (
        <TbCircleDot
          size={20}
          className={`${active === 10 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Rechteck",
      value: "RectangleRoi",
      icon: (
        <BiRectangle
          size={20}
          className={`${active === 11 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Eklipse",
      value: "EllipticalRoi",
      icon: (
        <BsCircle
          size={20}
          className={`${active === 12 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Invertieren",
      value: "Invert",
      icon: (
        <MdInvertColors
          size={20}
          className={`${active === 13 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "FlipV",
      value: "vflip",
      icon: (
        <CgEditFlipV
          size={20}
          className={`${active === 14 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "FlipH",
      value: "hflip",
      icon: (
        <CgEditFlipH
          size={20}
          className={`${active === 15 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Bidirektional",
      value: "Bidirectional",
      icon: (
        <BiDirections
          size={20}
          className={`${active === 16 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Radierer",
      value: "Eraser",
      icon: (
        <CiEraser
          size={20}
          className={`${active === 17 ? " fill-primary" : ""}`}
        />
      ),
    },
    {
      name: "Löschen",
      value: "Clear",
      icon: (
        <BsTrash
          size={16}
          className={`${active === 18 ? " fill-primary" : ""}`}
        />
      ),
    },
  ];

  return (
    <>
      <Popover className="bg-gray-100">
        <div>
          <div className="flex justify-between items-center py-2 lg:justify-start px-2 sm:px-4 lg:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <p>
                <span className="sr-only">Petleo</span>
                <img
                  className="h-10 w-auto sm:h-14"
                  src="./logo.png"
                  alt="Petleo"
                />
              </p>
            </div>
            <div className="lg:hidden">
              <a download="dicom.png" href={image}>
                <button className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm  text-white bg-primary cursor-pointer">
                  <HiDownload size={18} className=" -ml-2 mr-2" />
                  Herunterladen
                </button>
              </a>
            </div>
            <Popover.Group as="nav" className="hidden lg:flex space-x-6">
              <div
                onClick={() => handleClick("Series")}
                className={`cursor-pointer ${
                  imageIdsLength < 2 && width < 1045 ? "hidden" : ""
                }`}
              >
                {series ? (
                  <>
                    <TfiShiftLeft
                      size={24}
                      className={`mx-auto mb-[2px] ${
                        series ? " fill-primary" : "fill-black"
                      }`}
                    />
                    <span
                      className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                        series ? " text-primary" : "text-gray"
                      }`}
                    >
                      Serien
                    </span>
                  </>
                ) : (
                  <>
                    <TfiShiftRight
                      size={24}
                      className={`mx-auto mb-[2px] ${
                        series ? " fill-primary" : ""
                      }`}
                    />
                    <span
                      className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                        series ? " text-primary" : "text-gray"
                      }`}
                    >
                      Serien
                    </span>
                  </>
                )}
              </div>
              <div
                onClick={() => handleClick("StackScrollMouseWheel", 0, stack)}
                className={`cursor-pointer ${
                  activeImageLength < 2 ? "hidden w-0" : ""
                }`}
              >
                <ImStack
                  size={24}
                  className={`mx-auto mb-[2px] ${stack ? " fill-primary" : ""}`}
                />
                <span
                  className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                    stack ? " text-primary" : "text-gray"
                  }`}
                >
                  Stapelrolle
                </span>
              </div>
              <div
                onClick={() => handleClick("Pan", 1)}
                className="cursor-pointer"
              >
                <IoMdExpand
                  size={24}
                  className={`mx-auto mb-[2px] ${
                    active === 1 ? " fill-primary" : ""
                  }`}
                />
                <span
                  className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                    active === 1 ? " text-primary" : "text-gray"
                  }`}
                >
                  Schwenken
                </span>
              </div>

              <div
                onClick={() => handleClick("Zoom", 2)}
                className="cursor-pointer"
              >
                <TfiZoomIn
                  size={24}
                  className={`mx-auto mb-[2px] ${
                    active === 2 ? " fill-primary" : ""
                  }`}
                />
                <span
                  className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                    active === 2 ? " text-primary" : "text-gray"
                  }`}
                >
                  Zoom
                </span>
              </div>

              <div
                onClick={() => handleClick("Rotate", 3)}
                className="cursor-pointer"
              >
                <GrRotateRight
                  size={24}
                  className={`mx-auto mb-[2px] ${
                    active === 3 ? " fill-primary" : ""
                  }`}
                />
                <span
                  className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                    active === 3 ? " text-primary" : "text-gray"
                  }`}
                >
                  Rotieren
                </span>
              </div>

              <div
                onClick={() => handleClick("Wwwc", 4)}
                className="cursor-pointer"
              >
                <FaYinYang
                  size={24}
                  className={`mx-auto mb-[2px] ${
                    active === 4 ? " fill-primary" : ""
                  }`}
                />
                <span
                  className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                    active === 4 ? " text-primary" : "text-gray"
                  }`}
                >
                  Schattierungen
                </span>
              </div>

              <div
                onClick={() => handleClick("Angle", 5)}
                className="cursor-pointer"
              >
                <TfiAngleLeft
                  size={24}
                  className={`mx-auto mb-[2px] ${
                    active === 5 ? " fill-primary" : ""
                  }`}
                />
                <span
                  className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                    active === 5 ? " text-primary" : "text-gray"
                  }`}
                >
                  Winkel
                </span>
              </div>

              <div
                onClick={() => handleClick("Length", 6)}
                className="cursor-pointer"
              >
                <TfiRulerAlt
                  size={24}
                  className={`mx-auto mb-[2px] ${
                    active === 6 ? " fill-primary" : ""
                  }`}
                />
                <span
                  className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                    active === 6 ? " text-primary" : "text-gray"
                  }`}
                >
                  Länge
                </span>
              </div>

              <div
                onClick={() => handleClick("ArrowAnnotate", 7)}
                className="cursor-pointer"
              >
                <HiOutlineAnnotation
                  size={24}
                  className={`mx-auto mb-[2px] ${
                    active === 7 ? " fill-primary" : ""
                  }`}
                />
                <span
                  className={`text-base font-medium hover:text-gray-900 ${
                    active === 7 ? " text-primary" : "text-gray-500"
                  }`}
                >
                  Anmerkung
                </span>
              </div>

              <div
                onClick={() => handleClick("Reset", 8)}
                className="cursor-pointer"
              >
                <BiReset
                  size={24}
                  className={`mx-auto mb-[2px] ${
                    active === 8 ? " fill-primary" : ""
                  }`}
                />
                <span
                  className={`text-base font-medium hover:text-gray-900 ${
                    active === 8 ? " text-primary" : "text-gray-500"
                  }`}
                >
                  Zurücksetzen
                </span>
              </div>

              <Popover className="relative">
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-gray-100 rounded-md  items-center text-base font-medium hover:text-gray-900"
                      )}
                    >
                      <div>
                        <IoMdMore
                          size={24}
                          className={`mx-auto mb-[2px] ${
                            active === null ? "fill-primary" : ""
                          }`}
                        />
                        <span
                          className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                            active === null ? "text-primary" : "text-gray-500"
                          }`}
                        >
                          Mehr
                        </span>
                      </div>
                    </Popover.Button>
                    {open && (
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {moreTools.map((item, index) => (
                                <div
                                  key={item.name}
                                  href={item.href}
                                  onClick={() => {
                                    handleClick(item.value, index + 9);
                                    close();
                                  }}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 cursor-pointer"
                                >
                                  {item.icon}
                                  <div className="ml-4">
                                    <p
                                      className={`text-base font-medium ${
                                        active === index + 9
                                          ? "text-primary"
                                          : "text-gray-500"
                                      }`}
                                    >
                                      {item.name}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    )}
                  </>
                )}
              </Popover>
            </Popover.Group>
            <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
              <a download="dicom.png" href={image}>
                <button className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border border-transparent rounded-md shadow-sm font-medium text-white bg-primary ">
                  <HiDownload size={18} className="mr-2 ml-0" /> Herunterladen
                </button>
              </a>
            </div>
          </div>
        </div>
      </Popover>
      <hr></hr>

      <div className="lg:hidden flex flex-auto overflow-x-auto mt-2 gap-x-2 px-0 sm:px-2 pb-[4px]">
        {mobileTools.map((item, index) => {
          return (
            <div
              key={index}
              className={`px-2 flex flex-1 items-center border-[1px] border-[#dbecef] font-bold
               mx-1 h-8 rounded-lg text-sm cursor-pointer ${
                 activeImageLength < 2 && index === 0 ? "hidden w-0" : ""
               }`}
              onClick={() => handleClick(item.value, index, stack)}
            >
              {item.icon}
              <p
                className={`ml-2 text-gray-500 flex-auto ${
                  active === index ? "text-primary" : ""
                } ${stack && index === 0 ? "text-primary" : ""}`}
              >
                {item.name}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
}
